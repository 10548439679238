<template>
  <div class="order-list">
    <nav-bar :nav-info='navInfo' @screen="screen"></nav-bar>

    <!-- 筛选弹出框 -->
    <van-popup v-model="showScreen"  closeable round position="bottom">
     <div class="screen_title">筛选</div>
     <h4>类型</h4>
     <div class="screen_type">
       <div class="screen_type_item " :class="[isActiveIndex==0?'screen_type_check':'']" @click="checkType(0)">全部</div>
       <div class="screen_type_item" :class="[isActiveIndex==1?'screen_type_check':'']" @click="checkType(1)">发运订单</div>
       <div class="screen_type_item" :class="[isActiveIndex==2?'screen_type_check':'']" @click="checkType(2)">订单计划</div>
     </div>
    <h4>创建时间</h4>
    <div class="screen_time">
      <div>
        <el-date-picker type="date" placeholder="开始日期" value-format="yyyy-MM-dd"  v-model="screenForm.createTimeStart" style="width: 90%;"></el-date-picker>
      </div>
      <div>
        
        <el-date-picker type="date" placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="endTime" v-model="screenForm.createTimeEnd" style="width: 90%;"></el-date-picker>
      </div>
    </div>
    <div v-if="isActiveIndex!=2">
      <h4 v-if="isActiveIndex!=2">订单号</h4>
      <!-- <h4 v-else>计划号</h4> -->
      <div class="screen_orderNo" v-if="isActiveIndex!=2">
        <van-field v-model="screenForm.orderNo" clearable  placeholder="请输入订单号" />
      </div>
      <h4 >原始订单号</h4>
      <div class="screen_ys_orderNo">
        <van-field v-model="screenForm.autocOrderNo" clearable placeholder="请输入原始订单号" />
      </div>
      <template v-if="isActiveIndex!=2">
        <h4>用车企业</h4>
        <div class="screen_ys_orderNo">
          <van-field v-model="screenForm.erpName" clearable placeholder="请输入用车企业" />
        </div>
      </template>
    </div>
    <h4>收货单位</h4>
    <div class="screen_ys_orderNo">
      <van-field v-model="screenForm.address" clearable placeholder="请输入收货单位" />
    </div>
    <div class="screen_sub">
      <div @click="onReset">重置</div>
      <div @click="onScreenOrder">确定</div>
    </div>
    </van-popup>
    <form action="javascript:return ture">
      <van-search v-model="searchOrderValue"  shape="round" clearable  show-action background="#ffffff"  @clear="onCancelOrder"  @search="SearchOrder" placeholder="请输入搜索订单号/计划号">
        <template #action>
          <div @click="onCancelOrder">刷新</div>
        </template>
      </van-search>
    </form>
    <div v-if="noOrder">
      <div class="scorllList" v-infinite-scroll="getOrderList" infinite-scroll-delay="500"   infinite-scroll-disabled="scorllLoading" infinite-scroll-distance="50">
          <van-collapse v-model="activeNames">
            <div :name="index+''"  v-for="(item,index) in dataList" :key="index">
              <!-- <van-collapse-item :name="index+''"  v-for="(item,index) in dataList" :key="index" class="orderInfo"> -->
              <van-collapse-item  class="orderInfo">
                <template #title >
                  <!-- 常规订单 -->
                  <div class="order" v-if="item&&item.type==1">
                    <div class="order_tile">
                      <div class="order_tile_container">
                        <div class="order_title_img1"><van-image :src="require('@/assets/img/icon/cg_order2x.png')"></van-image></div>
                        <div class="order_no">订单编号：{{item.orderNo}}</div>
                        <div class="order_title_img2"><span class="fayun">发运订单</span></div>
                      </div>
                    </div>
                    <div class="order_container">
                      <div class="order_container_time">
                        <span>{{item.createTime | formatDate('YYYY.MM.DD HH:mm')}}</span>
                        <span>详情</span>
                      </div>
                      <div class="fenge"></div>             
                      <div class="order_userinfo">
                        <!-- check选择框 -->
                        <div class="order_userinfo_check"   @click.stop="selectList(item.orderNo,item.type)">
                          <div class="checked">
                            <van-checkbox-group @change="selectChange" v-model="checkedResult" ref="checkboxGroup">
                              <van-checkbox :name="item"  checked-color="#FD5C0C"></van-checkbox>
                            </van-checkbox-group>
                          </div>
                          
                        </div>
                        <!-- 右边订单信息 -->
                        <div class="order_userinfo_rigth">
                          <div class="order_userinfo_rigth1">
                            <div class="order_userinfo_rigth_item">
                              <div>
                                <van-image :src="require('@/assets/img/icon/order_user2x.png')" ></van-image>
                              </div>
                              <div>收货人：{{item.receiverName}}</div>
                            </div>  
                            <div class="order_userinfo_rigth_item " style="padding-left:2rem">
                              <div>
                                  <van-image :src="require('@/assets/img/icon/driver_tel2x.png')" ></van-image>
                              </div>
                              <div class="main_color">{{item.phone}}</div>    
                            </div>
                          </div>
                          <!-- 收货地址 -->
                            <div class="order_userinfo_rigth_adress">
                              <div class="adress_icon">
                                <van-image :src="require('@/assets/img/icon/order_adress2x.png')" ></van-image>
                              </div>
                              <div class="adress_text">{{item.address}}</div>
                            </div>   
                          <!-- 参数 -->
                          <div class="order_userinfo_rigth1">
                            <span>体积：{{item.cargoSize | toFixed(2) }}m³</span>
                            <span>重量：{{item.cargoWeight | toFixed(2)}}t</span>
                            <span>数量：{{item.cargoNum}}</span>
                          </div>
                        </div>
                      </div>
                    </div>  
                  </div>
                <!-- 计划订单 -->
                  <div class="order jh_userinfo" v-else-if="item&&item.type==2&&item.item.length!=0">
                    <div class="order_tile">
                      <div class="order_tile_container">
                        <div class="order_title_img1"><van-image :src="require('@/assets/img/icon/icon_ordercg@2x.png')"></van-image></div>
                        <div class="order_no">计划编号：{{item.item[0].transportNo}}</div>
                        <!-- <div class="order_title_img2"><van-image :src="require('@/assets/img/icon/order_status1.png')"></van-image></div> -->
                        <div class="order_title_img2"><span>订单计划</span></div>
                      </div>
                    </div>
                    <div class="order_container_jh">
                      <div class="order_container_time">
                        <span>{{item.item[0].createTime| formatDate('YYYY.MM.DD HH:mm')}}</span>
                        <span>详情</span>
                      </div>
                      <div class="fenge"></div>
                      
                      <div class="order_userinfo " >
                        <!-- check选择框 -->
                        <div class="order_userinfo_check"  @click.stop="selectList(item.item[0].orderCode,item.type)">
                          <div class="checked">
                            <van-checkbox-group  @change="selectChange" v-model="checkedResult" ref="checkboxGroup">
                              <van-checkbox :name="item"  checked-color="#FD5C0C"></van-checkbox>
                            </van-checkbox-group>
                          </div>
                          
                        </div>
                        <!-- 右边订单信息 -->
                        <div class="order_userinfo_rigth">
                          <div class="order_userinfo_rigth1">
                            <div class="order_userinfo_rigth_item">
                              <div>发货日期：{{item.item[0].orderGoodsDate}}</div>
                            </div>  
                             <div class="order_userinfo_rigth_item1">
                              <div>截止日期：{{item.item[0].endTime}}</div>
                            </div>  
                          </div>
                          <div class="order_userinfo_rigth1">
                            <div class="order_userinfo_rigth_item">  
                              <div>出发城市：{{item.item[0].sCity}}</div>    
                            </div>
                            <div class="order_userinfo_rigth_item1">
                              <div>目的城市：{{item.item[0].receiverCityName}}</div>
                            </div>  
                          </div>

                          <div class="order_userinfo_rigth1">
                            <div class="order_userinfo_rigth_item">
                              <div>结算类型：{{item.item[0].settlementsType}}</div>
                            </div>  
                            <div class="order_userinfo_rigth_item1">  
                              <!-- <div v-if="item.item[0].settlementsType =='整车'">计划：{{ sum(item.item[0].twelveT, item.item[0].fiveT,item.item[0].tenT,item.item[0].eightT)}}米</div>  -->
                              <div v-if="item.item[0].settlementsType =='整车'">计划：{{item.item[0].totalPlan || 0}}米</div>    

                              <div v-else>计划：{{item.item[0].totalPlan || 0}}立方</div>    
                              <!-- <div v-else>计划：{{sumVol(item)}}立方</div>     -->
                            </div>
                          </div>
                          <!-- 收货地址 -->
                          <div class="order_userinfo_rigth1">
                            <div class="order_userinfo_rigth_item">
                              <div>服务产品：{{item.item[0].serviceType}}</div>
                            </div>  
                          </div>
                          <!-- 参数 -->
                          <div class="order_userinfo_rigth1">
                          <div>卸货点信息：{{item.item[0].unloadingPoint|| '无'}}</div>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>
                </template>
                <!-- 折叠内容 -->
                <template>
                  
                  <!-- 常规订单 -->
                  <div v-if="item&&item.type == 1">
                    <h3 class="cg_color">管理订单</h3>
                    <div class="order_detail">
                      <span v-if="item.type==1"><i class="lable_cgcolor">订单类型：</i>常规订单</span>
                      <span v-else><i class="lable_cgcolor">订单类型</i>：非常规订单</span>
                      <span class="lableW" v-if="item.status==21"><i class="lable_cgcolor">订单状态：</i>待调度</span>
                      <span class="lableW" v-else-if="item.status==23"><i class="lable_cgcolor">订单状态：</i>在途</span>
                      <span class="lableW" v-else-if="item.status==24"><i class="lable_cgcolor">订单状态：</i>完成</span>
                    </div>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">用车企业：</i>{{item.erpName}}</span>
                    </div>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">到仓时间：</i>{{item.useTime | formatDate('YYYY.MM.DD HH:mm:ss')}}</span>
                    </div>
                    <h3 class="cg_color">基本信息</h3>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">预计完成时间：</i>{{item.expectFinishTime | formatDate('YYYY.MM.DD HH:mm')}}</span>
                      
                    </div>
                    <div class="order_detail">
                      <span v-if="item.dispatchType==1"><i class="lable_cgcolor">是否自由配送</i>：否</span>
                      <span v-else-if="item.dispatchType==2"><i class="lable_cgcolor">是否自由配送</i>：是</span>
                      <span class="lableW"><i class="lable_cgcolor">运单费用：</i>{{item.cost || 0}}元</span>
                    </div>

                    <h3 class="cg_color">发货信息</h3>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">发货人：</i>{{item.shipperName}}</span>
                    </div>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">联系方式：</i>{{item.shipperPhone || '无'}}</span>
                    </div>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">始发地：</i>{{item.shipperAddress}}</span>
                    </div>
                    <h3 class="cg_color">货物信息</h3>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">货物名称：</i>{{item.cargoType || '无'}}</span>
                    </div>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">货物类型：</i>{{item.cargoModel || '无'}}</span>
                      <!-- <span><i class="lable_cgcolor">声明价值：</i>{{item.cargoWorth}}元</span> -->
                      <span class="lableW"><i class="lable_cgcolor">声明价值：</i>{{item.cargoWorth}}元</span>
                    </div>
                    <h3 class="cg_color">收货信息</h3>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">子订单号：</i>{{item.autocOrderNo || '无'}}</span>
                    </div>
                    <div class="order_detail" style="padding-bottom:0.27rem">
                      <span><i class="lable_cgcolor">备注：</i>{{item.note||'无'}}</span>
                    </div>
                  </div>
                  <!-- 计划订单 -->
                  <div v-else-if="item&&item.type == 2&&item.item.length!=0">
                    <h3 class="jh_color">计划详情</h3>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">卸货点数：</i>{{item.item[0].pointNum || 0}}</span>
                    </div>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">附件：</i>{{item.item[0].enclosure || '无'}}</span>
                    </div>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">随货明细：</i>{{item.item[0].detailed2 || '无'}}</span>
                    </div>
                    <div class="order_detail">
                      <span><i class="lable_cgcolor">备注1：</i>{{item.item[0].barDescription || '无'}}</span>
                    </div>
                    <h3 class="jh_color">订单信息</h3>
                    <div class="order_child ">
                      <van-collapse v-model="activeNames1">
                        <van-collapse-item  :name="item1" v-for="(item1,index1) in item.item" :key="index1">
                          <template #title>
                            <div class="order_detail">
                              <span style="font-weight: bold">订单编号：{{item1.orderCode || '无'}}</span>
                            </div>
                            <div class="order_detail">
                              <span class="order_detail_color">数量：{{item1.quantity || 0}}</span>
                              <span class="order_detail_color" >方位：{{item1.volume |toFixed(2) }}立方</span>
                            </div>
                            <div class="order_detail">
                              <span class="order_detail_color">地址：{{item1.receiverLocationAddress || '无'}}</span>
                            </div>
                          </template>
                          <div class="order_detail">
                            <span><i class="lable_cgcolor">订货日期：</i>{{item1.orderGoodsDate || '无'}}</span>
                          </div>
                          <div class="order_detail">
                            <span><i class="lable_cgcolor">销售订单：</i>{{item1.orderCode || '无'}}</span>
                          </div>
                          <div class="order_detail">
                            <span><i class="lable_cgcolor">采购订单：</i>{{item1.cgOrder || '无'}}</span>
                          </div>
                          <div class="order_detail">
                            <span><i class="lable_cgcolor">行：</i>{{item1.lineNo || '无'}}</span>
                          </div>
                          <div class="order_detail">
                            <span><i class="lable_cgcolor">短缺量：</i>{{item1.shortage || '无'}}</span>
                          </div>
                          <div class="order_detail">
                            <span><i class="lable_cgcolor">体积：</i>{{item1.volume || 0}}m³</span>
                          </div>
                          <div class="order_detail">
                            <span><i class="lable_cgcolor">物料号：</i>{{item1.itemCode || '无'}}</span>
                          </div>
                          <div class="order_detail">
                            <span><i class="lable_cgcolor">产品型号：</i>{{item1.itemModel || '无'}}</span>
                          </div>
                          <div class="order_detail">
                            <span><i class="lable_cgcolor">城市：</i>{{item1.receiverCityName || '无'}}</span>
                          </div>
                          <div class="order_detail">
                            <span><i class="lable_cgcolor">名称：</i>{{item1.receiverName || '无'}}</span>
                          </div>
                          <div class="order_detail">
                            <span><i class="lable_cgcolor">地址：</i>{{item1.receiverLocationAddress || '无'}}</span>
                          </div>
                          <div class="order_detail">
                            <span><i class="lable_cgcolor">运输公司：</i>{{item1.transCompany || '无'}}</span>
                          </div>
                          <div class="order_detail ">
                            <span ><i class="lable_cgcolor ">备注：</i>{{item1.text1 || '无'}}</span>
                          </div>
                        </van-collapse-item>
                      </van-collapse>
                    </div>
                  
                  </div>
                </template>
                
              </van-collapse-item>  
            </div>    
          </van-collapse>
          <!-- 折叠结束 -->
      <!-- </van-list> -->
          <div class="mark" v-if="!loadMoreScorll">拼命加载中...</div>
          <div class="mark" v-else>已经到底了...</div>
      </div>
      <!-- 底部 -->
      <div class="footer">
        <div class="footer_left">
          <span>已选择{{checkedResult.length}}个订单</span>
          <span>发运订单共{{allVolume|toFixed(2)}} m³  {{allLoad|toFixed(2)}} t</span>
        </div>
        <div class="footer_rigth">
          <div @click="dispatchClick">派单</div>
        </div>
      </div>
    </div>
    <div v-else>
        <van-empty
          class="custom-image"
          image="https://img01.yzcdn.cn/vant/empty-image-default.png"
          description="没有找到订单信息"
        />
      </div>
    <!-- 司机列表弹出框 -->
    <van-popup v-model="showDispatch"  round position="bottom" >
      <div class="driver_search">
        <form action="javascript:return ture">
         <van-search v-model="searchValue"  @search="onSearchDrive"  @clear="onCancelDrive"  shape="round" placeholder="请输入司机姓名/车牌号"/>
        </form>
      </div>
      <div v-if="noDriver">
        <div class="driver" v-infinite-scroll="getDriverList" infinite-scroll-delay="500"   infinite-scroll-disabled="driverLoading" infinite-scroll-distance="50">
          <div v-for="(item2,index2) in driverData" :key="index2" >
            <div class="driver_name">
              <span>{{item2.realName}}</span>
              <span>{{item2.captainName}}</span>
              <span v-if="item2.workStatus == 2" style="color:#FB6600;font-size:0.32rem">待派单</span>
              <span v-else-if="item2.workStatus == 3" style="color:#999999;font-size:0.32rem">订单中</span>
            </div>
            <div class="driver_detail">
              <div class="driver_checked" >
                <div class="checked">
                  <van-checkbox-group v-model="checkedResultDriver" ref="checkboxGroup">
                    <van-checkbox :name="item2"  checked-color="#FD5C0C"></van-checkbox>
                  </van-checkbox-group>
                </div>       
              </div>
              <div class="driver_info">
                <div class="driver_info_item">
                  <span class="driver_tel">{{item2.phone}}</span>
                  <span v-if="item2.userId ==0">(公共)平台司机</span>
                  <span v-else>(企业)平台司机</span>
                </div>
                <div class="driver_info_item">
                  <span class="car_no">{{item2.carNo}}</span>
                  <span v-if="item2.carClass==1">车型：新能源车</span>
                  <span v-else-if="item2.carClass==2">车型：小面</span>
                  <span v-else-if="item2.carClass==3">车型：燃油车</span>
                  <span v-else-if="item2.carClass==4">车型：混合动车</span>
                  <span v-else>车型：未知</span>
                </div>
                <div class="driver_info_item">
                  <span >剩余容积：{{item2.surplusMdlVolume|toFixed(2)}}m³</span>
                  <span class="params">剩余载重：{{item2.surplusMdlLoad|toFixed(2)}}t</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mark" v-if="!loadMoreDriver">拼命加载中...</div>
          <div class="mark" v-else>已经到底了...</div>
        </div>
      <!-- 司机列表底部 -->
      <div class="bottom_driver">
        <div class="bottom_driver_left" @click="showDispatch = false">取消</div>
        <div class="bottom_driver_rigth" @click="determine">确定</div>
      </div>

      </div> 
      <div v-else>
        <van-empty
          class="custom-image"
          image="https://img01.yzcdn.cn/vant/empty-image-default.png"
          description="没有找到司机信息"
        />
      </div>
     
    </van-popup>
  </div>
</template>
<script>
import { Dialog, Toast } from 'vant';
import NavBar from '../../components/NavBar/NavBar.vue'
export default {
  components: {
    NavBar
  },
  data() {
    return {
      //navbar信息
      navInfo:{
        title:'订单列表',
        left:true,//左边图标
        rigth:true,//右边图标
        path:'/home'//左边返回的页面地址
      },
      searchValue:'',//搜索框司机名字
      searchOrderValue:'',//搜索框订单号
      activeNames:[],//折叠的订单信息
      activeNames1:[],//折叠的子订单信息，vant组件
      showScreen:false,//筛选框显示状态
      showDispatch:false,//司机弹出框状态
      isActiveIndex:0,//条件筛选订单类型0:全部、1：发运订单、2：订单计划
      dataList:[],//订单计划列表
      // loading: false,
      driverLoading:true,//下拉加载更多状态，为true时，可以加载，为false时，加载完全
      loadMoreDriver:false,//拼命加载中...司机列表底部提示
      scorllLoading:false,//下拉加载更多状态，为true时，可以加载，为false时，加载完全
      loadMoreScorll:false,//拼命加载中...订单底部提示
      checkedResult:[],//选中的订单项
      checkedResultJh:[],//选中的计划订单项
      checkedResultDriver:[],//选中的司机项
      //筛选条件
      screenForm: {
        createTimeStart:'',//开始时间
        createTimeEnd:'',//结束时间
        address:'',//地址
        orderNo:'',//订单号
        autocOrderNo:'',//原始订单号
        erpName:'',//企业名称
        type:''//查找类型
      },
      driverData:[],//司机列表
      orderStr:'',//订单ID
      orderType:true,//订单类型：订单和计划，为true时则选中的订单类型一样，为false时选中的订单类型不一样
      driverParams:{
        start:0,
        length:20
      },
      orderParams:{
        page:0,
        size:10
      },
      allVolume:0,//总体积
      allLoad:0,//总重量
      noDriver:true,//没有司机
      noOrder:true,//没有订单
    }
  },
  created() {
  },
  methods: {
    //去派单
    determine() {
      Dialog.confirm({
      title: '派单',
      message: '是否确认派单',
    })
      .then(async() => {
        let flag = 0
        this.checkedResult.map(item=> {
          flag = item.type
        })
        if (flag==1 && this.checkedResultDriver.length>1) {
          Toast("常规订单只能选择一个司机哦")
          return false
        } else 
        if(this.checkedResultDriver.length<=0) {
          Toast("请先选择司机")
          return false
        } else if(flag==2 && this.checkedResult[0].item[0].serviceType!="干线运输正向"&& this.checkedResultDriver.length>1) {
          Toast("售后配件运输类型只能选择一个司机哦")
          return false
        }
        
        //常规派单 可以派多个司机
        if(flag == 1) {
          let params = {
            userId:this.checkedResultDriver[0].userId,
            driverId:this.checkedResultDriver[0].id,
            realName:this.checkedResultDriver[0].realName,
            phone:this.checkedResultDriver[0].phone,
            carNo:this.checkedResultDriver[0].carNo,
            orderId:this.orderStr,
          }
          //createWaybill 普通订单派单接口
          let res = await this.$Api.createWaybill(params)
          if (res.data.msg=='派单成功') {
            Toast.success("派单成功")
            setTimeout(() => {
              this.showDispatch = false
              this.orderParams.page = 0;
              this.dataList=[];
              this.getOrderList()
              this.checkedResult=[]
              this.checkedResultDriver=[]
            }, 500);
          } else {
            Toast.fail("派单失败，请重新派单")
          }
        }

        //计划派单
        if(flag==2) {
          let driverIdList = []
          this.checkedResultDriver.map(item=> {
            driverIdList.push(item.id)
          })
          let paramsJh = {
            userId:this.checkedResultDriver[0].userId,
            driverId:driverIdList,
            id:this.$store.getters.getUserInfo.id,
            planId:this.checkedResult[0].item[0].planId
          }
          //createTransportPlan 计划派单接口
          let resJh = await this.$Api.createTransportPlan(paramsJh)
          let params = {
              userId:this.checkedResultDriver[0].userId,
              driverId:this.checkedResultDriver[0].id,
              realName:this.checkedResultDriver[0].realName,
              phone:this.checkedResultDriver[0].phone,
              carNo:this.checkedResultDriver[0].carNo,
              orderId:this.orderStr,
            }
          let res = {}
          if (this.checkedResult[0].item[0].serviceType!="干线运输正向") {
             res = await this.$Api.createWaybill(params)
          }
          if (this.checkedResult[0].item[0].serviceType=="干线运输正向"&&resJh.data.msg=='订单计划派车成功' ) {
            Toast.success("派单成功")
            setTimeout(() => {
              this.showDispatch = false
              this.orderParams.page = 0;
              this.dataList=[];
              this.getOrderList()
              this.checkedResult=[]
              this.checkedResultDriver=[]
            }, 500);
          } else if(this.checkedResult[0].item[0].serviceType!="干线运输正向"&&resJh.data.msg=='订单计划派车成功'&&res.data.msg=='派单成功') {
            Toast.success("派单成功")
            setTimeout(() => {
              this.showDispatch = false
              this.orderParams.page = 0;
              this.dataList=[];
              this.getOrderList()
              this.checkedResult=[]
              this.checkedResultDriver=[]
            }, 500);
          }
          else {
            Toast.fail("派单失败，请重新派单")
          }
        }
      })
      .catch(() => {
        console.log(111);
      });
    },
    //弹出司机列表
    async  dispatchClick() {
      if (this.checkedResult.length<=0) {
        Toast("请先选择订单，再进行派单哦")
        return false
      }
      let orderArr = []
      let arrLength = []
      this.checkedResult.map((item,index,arr)=> {
        //判断是否选择同一种类型的订单
        if (arr[0].type !=item.type) {
          Toast("请选择相同类型的订单")
          this.orderType=false
          return false
        }  else {
          this.orderType=true
        }
        if (item.type == 1) {
          orderArr.push(item.orderId)
        } else if(item.type == 2) {
          arrLength.push(item)
            item.item.map(item1=> {
              orderArr.push(item1.orderId)
            })  
        }
        if (arrLength.length>1) {
          Toast("订单计划不能批量派单哦")
          this.orderType=false
          return false
        }
      })
      this.orderStr = orderArr.join(',')
      if (this.orderType) {
        this.driverData = []
        this.getDriverList()
        this.showDispatch=true
      }
    },
    //获得司机列表
    async  getDriverList(driverNameValue) {
      // console.log(driverNameValue);
        this.driverParams.start = this.driverData.length
        let params = {
          userId:this.$store.getters.getUserInfo.deptId,
          orders:this.orderStr,
          start:this.driverParams.start,
          length:20,
          driverName:driverNameValue
        }
        let res = await this.$Api.driverSelectDriverList(params)
        if (res.data.success==true) {
           let resData = res.data.data
           if (resData.length ==0 &&this.driverData.length ==0 ) {
              this.noDriver = false
            }else {
              this.noDriver = true
            }
          this.driverData = this.driverData.concat(resData)
          //判断最后一页是否还有数据
          if(resData.length < 20) {
            this.driverLoading = true
            this.loadMoreDriver = true
          } else {
            this.driverLoading = false
            this.loadMoreDriver = false
          }
        }    
    },
    onSearchDrive(value) {
      this.driverData = [],
      this.getDriverList(value)
    },
    onCancelDrive() {
      this.driverData = [],
      this.getDriverList()
    },
    // 点击选择筛选按钮类型
    checkType(index) {
      console.log(index);
      this.isActiveIndex =index
      if (index ==1) {
        this.screenForm.type = '1'
      } else if(index ==2) {
        this.screenForm.type = '2'
      }else {
        this.screenForm.type = ''
      }
    },
    selectDriver() {
      console.log(11);
    },
    selectList(order,type) {
      console.log(111);
    },
    //选中check触发
    selectChange(checked) {
      let arrVolume = []
      checked.map(item=> {
        if (item.type ==1) {
          arrVolume.push(item.cargoSize)
        } else {
          item.item.map(item1=> {
            arrVolume.push(item1.volume)
          })
        }
      })
    },
    screen(value) {
      this.showScreen = value
    },
    onLoad() {
      console.log(111);
    },
    async getOrderList(type) {
      //如果type==1则是筛选框请求的，则重置筛选框
      // if(type==1) {
        // for (const key in this.screenForm) {
        //   this.screenForm[key] = ''
        // }
      // } 
      this.orderParams.page++
      let params = {
        userId:this.$store.getters.getUserInfo.deptId,
        page:this.orderParams.page,
        size:this.orderParams.size,
        createTimeStart:this.screenForm.createTimeStart,//开始时间
        createTimeEnd:this.screenForm.createTimeEnd,//结束时间
        address:this.screenForm.address,//地址
        orderNo:this.screenForm.orderNo,//订单号
        autocOrderNo:this.screenForm.autocOrderNo,//原始订单号
        erpName:this.screenForm.erpName,//企业名称
        type:this.screenForm.type,
      }
      //getOrderList 获取订单列表
      let res = await this.$Api.getOrderList(params)
      if (res.status!=200) return false
      let resData = res.data.list
      
 
      if (this.orderParams.page >=res.data.totalPage) {
        this.scorllLoading = true
        this.loadMoreScorll = true
      } else {
        this.scorllLoading = false
        this.loadMoreScorll = false
      }
      this.dataList = this.dataList.concat(resData)  
      if (this.dataList.length==0) {
        this.noOrder = false
      }else {
        this.noOrder = true
      }
    },   
    //计算客户整车数量
    sum(a,b,c,d) {
      let arr = []
      let sum = 0
      arr.push(a,b,c,d)
      for (let i = 0; i < arr.length; i++) {
       sum += Number(arr[i]) 
      }
      return sum
    },
    //计算客户的计划容积
    sumVol(value) {
      let sum = 0
      value.item.map(element => {
        sum += Number(element.ldSize)
      })
      return sum
    },
    //清除筛选条件
    onCancelOrder() {
      this.searchOrderValue = ''
      this.dataList = []
      this.screenForm.createTimeStart=''//开始时间
      this.screenForm.createTimeEnd=''//结束时间
      this.screenForm.address=''//地址
      this.screenForm.orderNo=''//订单号
      this.screenForm.autocOrderNo=''//原始订单号
      this.screenForm.erpName=''//用车企业
       this.screenForm.type= ''
      this.orderParams.page = 0
      this.checkedResult = []
      this.getOrderList()
    },
    //根据筛选条件搜过
    onScreenOrder() {
      this.dataList = []
      this.orderParams.page = 0
      this.getOrderList(1)
      this.showScreen = false
    }, 
    //清除筛选条件
    onReset() {
      for (const key in this.screenForm) {
        this.screenForm[key] = ''
      }
    },
    //根据单号搜索订单
    async SearchOrder(value) {
      this.checkedResult = []
      this.checkedResultJh = []
      let params = {
        orderNo:value
      }
      let res = await this.$Api.queryByOrderNo(params)
      if (res.data.length!=0) {
        this.noOrder = true
      } else {
        this.noOrder = false
      }
      this.dataList = res.data
      this.scorllLoading = true
      this.loadMoreScorll = true
    }
  },
  computed: {
    	//大于开始时间
			endTime() {
				return {
					 disabledDate: time => {
						return time.getTime() < new Date(this.screenForm.createTimeStart).getTime()- 86400000
					}
				}
			}
  },
  watch: {
    // 监听选中的订单变化
    checkedResult:{
      handler(val,oldVal) {
        let sumVolume = 0
        let sumLoad = 0
        let resArrVolume = []
        let resArrLoad = []
        val.map(element=> {
          if (element.type==1) {
            resArrVolume.push(element.cargoSize)
            resArrLoad.push(element.cargoWeight)
          } else {
          }
        })
        for (let i = 0; i < resArrVolume.length; i++) {
          sumVolume += Number(resArrVolume[i]) 
        }
        for (let i = 0; i < resArrLoad.length; i++) {
         sumLoad += Number(resArrLoad[i]) 
        }
        this.allVolume = sumVolume
        this.allLoad = sumLoad
      },
      deep:true
    }
  }
}
</script>
<style lang="scss" scoped>
html,
body {
	height: 100%;
}
.data_list {
  margin-bottom: 1.33rem;
}
/deep/.van-search {
  height: 7vh;
}
.order-list {
  // background-color: #ddd7d7;
  height: 100vh;
  overflow: hidden;
  // padding-bottom: 1.2rem;
  /deep/.van-tabs__line{
		background-color:#FD5C0C!important;
    width: 20vw;
		}
    .orderInfo {
      /deep/.van-cell {
        background-color: #F5F5F5;
        padding: 0.1666rem 0;
      }
      .order {
        // width: 9.2rem;
        margin-right: 0.1rem;
        font-size: 0.37rem;
        .order_container {
          // background-color: pink;
          background-color: #FFFFFF;
          // height: 3.28rem;
          border-radius: 0.3rem;
        }
        .order_container_jh {
        
          background-color: #FFFFFF;
          border-radius: 0.3rem;
      }
          .order_container_time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              padding:5px 0.27rem;
              color: #999999;
            }
            span:nth-child(2) {
              padding-right: 1.41rem;
            }
          }       
          .order_userinfo {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 0;
            .order_userinfo_check {
              width: 1.04rem;
              height: 100%;
              display: flex;
              justify-content: center; 
              align-items: center;
              .checked {
                width: 0.5rem;
                height: 0.5rem; 
                /deep/.van-checkbox__icon .van-icon {
                  width: 0.5rem;
                  height: 0.5rem;  
                }
              }
            }
            .order_userinfo_rigth {
              flex: 1;
              height: 100%;
              padding-right: 0.3rem;
              .order_userinfo_rigth1{
                // padding-top: 0.21rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                  // height: 0.53rem;
                  span {
                    color: #999999;
                  }
                .order_userinfo_rigth_item {
                  flex: 1;
                  display: flex;
                  justify-content: left;
                  align-items: center;
                  div {
                    height: 100%;
                    line-height: 0.53rem;
                    text-align: left;
                  }
                  /deep/.van-image {
                    width: 0.64rem;
                    height: 0.64rem;
                  }
                }
                .order_userinfo_rigth_item1 {
                  width: 4.1rem;
                }    
              }
            }
          }
      }
    }
}
.order_userinfo_rigth_adress {
  position: relative;
  .adress_icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.64rem;
    height: 0.64rem;
  }
  .adress_text {
    padding-left: 24px;
  }
}
// 折叠按钮
/deep/.van-cell__right-icon {
  margin-left: -0.63rem;
  // margin-right: 1rem;
  margin-top: 0.8rem;
}
/deep/.van-collapse-item__content {
    padding: 0;
  }
.order_child {
  font-size: 0.37rem;
  /deep/.van-cell {
    padding: 0;
    background: #FFFFFF!important;
  }
  /deep/.van-cell__title {
    margin-top:-0.28rem
  }
  /deep/.van-collapse-item__content {
    background: rgba($color: #FB6600, $alpha: 0.03);
  }
  
  /deep/.van-cell__right-icon  {
    position: absolute;
    top: -0.16rem!important;
    right: 0.26rem;
    margin: 0;
  }
  /deep/.van-collapse-item--border::after {
    border: 0;
  }
  /deep/.van-collapse-item__content {
    padding: 0;
  }
}
 .order_tile_container {
   padding-left: 0.2rem;
  display:flex;
  align-items: center;
  justify-items: center;
  .order_title_img1 {
    width: 0.64rem;
    height: 0.64rem;
  }
  .order_title_img2 {
    width: 1.55rem;
    height: 0.48rem;
    padding-left: 0.57rem;
    padding-bottom: 0.1rem;
    span {
      display: block;
      font-size: 0.37rem;
      text-align: center;
      width: 100%;
      height: 100%;
      line-height: 0.52rem;
      border-radius: 0.1rem;
      border: 1px solid #6C63FF;
      color: #6C63FF;
      padding: 0 0.1rem;
    }
    .fayun {
      border: 1px solid #1AA3FF;
      color: #1AA3FF;
    }
  }
  .order_no {
    color: #999999;
    width: 6.44rem;
    height: 0.53rem;
    font-size: 0.37rem;
    line-height: 0.53rem;
  }
 
}
.fenge {
   height: 0.03rem;
   width: 9.61rem;
   margin:0 0.27rem; 
   background: #F5F5F5;        
}


.main_color {
  padding-right: 5px;
  color: #FD5C0C;;
}
// 底部
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 8vh;
  width: 100%;
  box-shadow:0 0.1rem 0.1rem 0.1rem #cccccc;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .footer_left {
    flex: 1;
    padding-left: 0.5rem;
    height: 100%;
    font-size: 2.5vh;
    // font-size: 0.37rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span:nth-child(2) {
      // font-size: 0.32rem;
      font-size: 2vh;
      color: #999999;
    }
  }
  .footer_rigth {
    flex: 1;
    height: 100%;
    font-size: 2.4vh;
    display: flex;
    align-items: center;
    position: relative;
    div {
      position: absolute;
      right: 0;
      margin-right: 0.5rem;
      height: 5vh;
      width: 2.13rem;
      text-align: center;
      border-radius: 0.85rem;
      line-height: 5vh;
      color: #FFFFFF;
      background: #FB6600;
    }
  }
}

// screen弹出框
.screen_title {
  font-size: 0.43rem;
  text-align: center;
  line-height: 0.59rem;
  padding-top: 0.27rem;
}
h4 {
  font-size: 0.37rem;
  font-weight: 400;
  line-height: 0.53rem;
  padding: 0.27rem 0 0.17rem 0.27rem;
}
h3 {
  font-size: 0.37rem;
  padding: 0 0.43rem;
}
.screen_type {
  height: 0.8rem;
  font-size: 0.32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .screen_type_item {
    margin: 0.3rem;
    width: 2.35rem;
    height: 100%;
    background: #F5F5F5;
    text-align: center;
    line-height: 0.8rem;
    border-radius: 0.8rem;
    color: #999999;
  }
  .screen_type_check {
    color: #FB6600;
    background: rgba($color: #FB6600, $alpha: 0.1);
  }
}
.screen_orderNo,.screen_ys_orderNo,.screen_ys_orderNo {
  width: 9.15rem;
  height: 0.8rem;
  border: #FB6600;
  margin: 0 auto;
}
.van-cell {
  background: #F5F5F5;
  border-radius: 0.8rem;
  padding: 0.15rem 0.4rem;
}
.screen_sub {
  height: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.45rem;
  margin-bottom: 0.35rem;
  div {
    height: 100%;
    width: 2.13rem;
    border-radius: 0.85rem;
    text-align: center;
    color: #FFFFFF;
    font-size: 0.43rem;
    line-height: 0.85rem;
    background: #FB6600;
    margin: 0 30px;
  }
  div:nth-child(1) {
    background: #cccccc;
  }
}
.screen_time {
  width: 9.15rem;
  height: 0.8rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    width: 4.21rem;
    /deep/.el-input__inner {
      border-radius: 0.8rem;
      height: 0.8rem;
       background: #F5F5F5;
       border: 0;
       line-height: 0.8rem;
      margin-top: -0.2rem;
    }
    /deep/.el-input__icon {
      opacity: 0;
    }
  }
}
.order_detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.37rem;
  padding: 0 0.43rem;
  span {
    text-align: left;
  }
  span:nth-child(1) {
      flex: 1;
    }
   span:nth-child(2) {
      // width: 6.4rem;
      text-align: left;
    }
    .lableW {
      width: 3.5rem;
    }
}
// 司机列表弹框
.driver_name {
  height: 0.6rem;
  display: flex;
  align-items: center;
padding: 0 0.3rem;
  span:nth-child(1) {
    font-size: 0.43rem;
    width: 1.8rem;
  }
  span:nth-child(2) {
    font-size: 0.34rem;
    color: #999999;
    flex: 1;
  }
  span:nth-child(3) {
    font-size: 0.37rem;
    width: 1.3rem;
  }
}
.driver {
  overflow: auto;
  height: 10rem;
}
.driver_detail {
  display: flex;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #F5F5F5;
  font-size: 0.37rem;
  .driver_checked {
    width: 1.04rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .checked {
      width: 0.5rem;
      height: 0.5rem; 
      /deep/.van-checkbox__icon .van-icon {
        width: 0.5rem;
        height: 0.5rem;  
      }
    }
  }
  .driver_info {
    flex: 1;
    padding-top: 0.1rem;
    font-size: 0.37rem;
    .driver_info_item {
      height: 0.64rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .driver_tel {
        background-image: url('../../assets/img/icon/driver_tel2x.png');
        background-repeat: no-repeat;
        background-size: 0.64rem;
        padding-left: 0.64rem;
      }
       .car_no {
        background-image: url('../../assets/img/icon/car_no2x.png');
        background-repeat: no-repeat;
        background-size: 0.64rem;
        padding-left: 0.64rem;
      }
      .params {
        padding-left: 0.64rem;
      }
      span {
        flex: 1;
        height: 100%;
        line-height: 0.64rem;
      }
      span:nth-child(2) {
        width: 4rem;
      }
    }
  }
}
.bottom_driver {
  background: #FFFFFF;
  position: fixed;
  border-radius: 0.2rem;
  bottom: 0;
  left: 0;
  height: 1.2rem;
  width: 10rem;
  color: #FFFFFF;
  font-size: 0.37rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    margin:0 1rem;
  }
  .bottom_driver_left {
    width: 3.75rem;
    height: 0.85rem;
    background: #CCCCCC;
    text-align: center;
    line-height: 0.85rem;
    border-radius: 0.85rem;
  }
  .bottom_driver_rigth {
    width: 3.75rem;
    height: 0.85rem;
    background: #FB6600;
    text-align: center;
     line-height: 0.85rem;
    border-radius: 0.85rem;
  }
}
.driver {
  padding-bottom: 2rem;
}
.cg_color {
  color: #FB6600;
  font-size: 0.37rem;
  padding: 0.2rem 0.43rem 0.1rem;
}
.lable_cgcolor {
  font-style: normal;
  color: #000000;
}
.jh_color {
  // color: #6C63FF;
  color: #FB6600;
  font-size: 0.37rem;
  padding: 0.27rem 0.43rem 0.1rem;
}
//加载提示
.mark {
  text-align: center;
  font-size: 0.37rem;
  // background: #F5F5F5;
  color: #cccccc;
}

/deep/.van-search__content {
  background-color: #f5f5f5;
}

.scorllList {
  height: 78vh;
  overflow-y: auto;
  padding-bottom: 1.53rem;

}

/deep/.van-collapse-item--border::after {
  border: 0;
}
.order_detail_color {
  color: #999999;
}
.order_userinfo_rigth_item1 {
  width: 3.5rem;
}
/deep/[class*=van-hairline]::after {
  border:0;
}
/deep/.van-collapse-item__title {
  position: relative;
  .van-cell__right-icon {
    position: absolute;
    top: 0.16rem;
    right: 0.6rem!important;
  }
}

/deep/.van-popup {
  max-height: 75%;
  z-index: 1000!important;
}
/deep/.el-picker-panel .el-date-picker .el-popper {
  z-index: 2999!important;
}
/deep/.van-overlay {
  z-index: 889!important;
}

</style>