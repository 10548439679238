<template>
  <div>
    <van-nav-bar :title="navInfo.title"  class="nav">
      <template #left v-if="navInfo.left">
        <van-icon name="arrow-left"  size="24" color="#ffffff"  @click="backPage"/>
      </template>
      <template #right  v-if="navInfo.rigth">
        <van-image class="menu" v-if="!navInfo.menu" :src="require('@/assets/img/icon/nav_bar_rigth.png')" @click="screen"></van-image>
        <van-image class="menu"  v-else :src="require('@/assets/img/icon/menuLogin.png')" @click="screen"></van-image>
      </template>
    </van-nav-bar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      iconLeft:require('@/assets/img/icon/jt1.png')
    }
  },
  props: {
    navInfo: {
      type:Object,
      default:{}
    }
  },
  created() {

  },
  methods: {
    backPage() {
      let path = this.navInfo.path
      this.$router.push(path)
    },
    //筛选
    screen() {
      //向父元素传递事件
      this.$emit('screen',true)
    }
  }
}
</script>
<style lang="scss" >
.nav {
  background-color: #061F3E;
  color: #333333;
  height: 7vh;
  .van-nav-bar__title {
    color: #ffffff;
  }
}
.van-hairline--bottom:after {
  border-bottom-width: 0px;
}
.menu {
  width: 0.64rem;
  height: 0.64rem;
}
</style>